import React from 'react'

import 'twin.macro'
import { Section } from '../section'

const Benefits = ({
  benefits = [],
  padding,
  title,
}: {
  benefits: {
    description: string
    slotTitle: string
  }[]
  padding?: 'none' | 'normal' | 'top' | 'bottom'
  title: string
}) => {
  return (
    <Section padding={padding}>
      <div tw="relative px-6 md:(px-8) lg:(px-12)">
        <div tw="flex flex-col justify-start">
          {title && (
            <div tw="w-full text-gray-600">
              <h2 tw="text-left text-4xl font-thin leading-tighter max-w-md md:(pr-8)">
                {title}
              </h2>
            </div>
          )}
          <div tw="flex flex-wrap w-full relative md:(justify-between)">
            {benefits.map((benefit, idx) => (
              <div key={idx} tw="w-full relative mt-12 md:(w-1/2 mt-10 px-2)">
                <h3 tw="text-gray-600 text-left text-3xl font-thin leading-tight max-w-xs md:max-w-md pr-10">
                  {benefit.slotTitle}
                </h3>
                <p tw="text-gray-700 text-lg max-w-sm font-light leading-normal mt-2 md:mt-4">
                  {benefit.description}
                </p>
              </div>
            ))}
          </div>
        </div>
      </div>
    </Section>
  )
}

export default Benefits
