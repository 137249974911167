import React, { useContext, useEffect } from 'react'
import 'twin.macro'

import SEO from 'components/shared/seo'
import { useBuildZoomItems } from 'content-queries/buildzoom/buildzoom'
import { AutomaticPopupContext } from 'context/automatic-popup/provider'
import fireEvent from 'context/tracking/events'
import { AutomaticPopup } from 'mx/automatic-popup'
import Benefits from 'mx/buildzoom/benefits'
import { SectionTemplateContent } from 'mx/buildzoom/section-template-content'
import BrandsSection from 'mx/compass/brands-section'
import { CustomBottomCTA } from 'mx/custom-bottom-cta'
import Layout from 'mx/layout'
import { FormContactSource } from 'types/crm'

const BuildZoomPage = () => {
  useEffect(() => {
    dispatch({
      type: 'popup_enabled',
      value: true,
    })
    fireEvent({
      type: 'page_viewed',
      pageTitle: 'FORM BuildZoom',
    })
  }, [])

  const { dispatch } = useContext(AutomaticPopupContext)
  const { data: buildZoomItemsData } = useBuildZoomItems()
  const buildZoomItemsCollection: any[] = buildZoomItemsData.map((n: any) => ({
    url: n.node.actions?.url,
    text: n.node.actions?.text,
    description: n.node.description,
    image: n.node.image.localFile.childImageSharp.gatsbyImageData,
    sections: n.node.sectionModules,
    title: n.node.title,
  }))

  return (
    <Layout>
      <AutomaticPopup
        buttonText={
          buildZoomItemsCollection[0].sections[5]?.moduleMisc.buttonText
        }
        contactSource={FormContactSource.POPUP_BUILDZOOM}
        guideName={
          buildZoomItemsCollection[0].sections[5]?.moduleMisc.guideName
        }
        guideSource={
          buildZoomItemsCollection[0].sections[5]?.moduleMisc.guideSource
        }
        popupDescription={
          buildZoomItemsCollection[0].sections[5]?.moduleMisc.popupDescription
        }
        popupImage={
          buildZoomItemsCollection[0].sections[5]?.images[0]?.localFile
            .childImageSharp.gatsbyImageData
        }
        popupTitle={
          buildZoomItemsCollection[0].sections[5]?.moduleMisc.popupTitle
        }
      />
      <BrandsSection
        formLogo={buildZoomItemsCollection[0].sections[0]?.images[1]?.file.url}
        partnerLogo={
          buildZoomItemsCollection[0].sections[0]?.images[0]?.file.url
        }
      />
      <SectionTemplateContent
        buttonLink={buildZoomItemsCollection[0].url}
        buttonText={buildZoomItemsCollection[0].text}
        description={buildZoomItemsCollection[0].description}
        image={buildZoomItemsCollection[0].image}
        includeButton={true}
        isWide={true}
        padding={'top'}
        title={buildZoomItemsCollection[0].title}
      />
      <SectionTemplateContent
        description={
          buildZoomItemsCollection[0].sections[1]?.moduleMisc.description
        }
        image={
          buildZoomItemsCollection[0].sections[1]?.images[0]?.localFile
            .childImageSharp.gatsbyImageData
        }
        isLeft={buildZoomItemsCollection[0].sections[1]?.moduleMisc.isLeft}
        isWide={true}
        padding={'top'}
        title={buildZoomItemsCollection[0].sections[1]?.moduleTitle}
      />
      <Benefits
        benefits={buildZoomItemsCollection[0].sections[2]?.contentSlots}
        padding={'top'}
        title={buildZoomItemsCollection[0].sections[2]?.moduleTitle}
      />
      <SectionTemplateContent
        buttonLink={
          buildZoomItemsCollection[0].sections[3]?.moduleMisc.action.url
        }
        buttonText={
          buildZoomItemsCollection[0].sections[3]?.moduleMisc.action.text
        }
        description={
          buildZoomItemsCollection[0].sections[3]?.moduleMisc.description
        }
        includeButton={true}
        includeSecondaryButton={true}
        image={
          buildZoomItemsCollection[0].sections[3]?.images[0]?.localFile
            .childImageSharp.gatsbyImageData
        }
        padding={'top'}
        secondaryButtonLink={
          buildZoomItemsCollection[0].sections[3]?.moduleMisc.action
            .secondaryUrl
        }
        secondaryButtonText={
          buildZoomItemsCollection[0].sections[3]?.moduleMisc.action
            .secondaryText
        }
        title={buildZoomItemsCollection[0].sections[3]?.moduleTitle}
        quote={buildZoomItemsCollection[0].sections[3]?.moduleMisc.quote}
      />
      <CustomBottomCTA content={buildZoomItemsCollection[0].sections[4]} />
    </Layout>
  )
}
export default BuildZoomPage

export const Head = () => {
  const { data: buildZoomItemsData } = useBuildZoomItems()
  const buildZoomItemsCollection: any[] = buildZoomItemsData.map((n: any) => ({
    url: n.node.actions?.url,
    text: n.node.actions?.text,
    description: n.node.description,
    image: n.node.image.localFile.childImageSharp.gatsbyImageData,
    sections: n.node.sectionModules,
    title: n.node.title,
  }))

  return (
    <SEO
      description="FORM has partnered with BuildZoom, so you don’t have to worry about finding a great vetted, bonded, and insured contractor for install and general contracting services on your project."
      path="/buildzoom"
      title="BuildZoom"
      image={buildZoomItemsCollection[0].image.images.fallback.src}
    />
  )
}
