import React from 'react'

import { motion } from 'framer-motion'
import { navigate } from 'gatsby'
import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image'
import tw from 'twin.macro'

import {
  MxButton as Button,
  MxSectionContainer,
  MxSectionDescription,
  MxSectionFirstContainer,
  MxSectionSecondContainer,
  MxSectionTextContainer,
  MxSectionTitle,
} from '../components/section-components'
import { Section } from '../section'

const variants = {
  visible: {
    opacity: 1,
    y: 0,
    transition: { ease: 'easeOut', delay: 0.25, duration: 0.75 },
  },
  hidden: { opacity: 0, y: 75 },
}

interface Props {
  buttonLink?: string
  buttonText?: string
  description?: string
  image?: IGatsbyImageData
  includeButton?: boolean
  includeSecondaryButton?: boolean
  includeInput?: boolean
  isLeft?: boolean
  isWide?: boolean
  padding?: 'none' | 'normal' | 'top' | 'bottom'
  secondaryButtonLink?: string
  secondaryButtonText?: string
  title?: string
  quote?: string
}
export const SectionTemplateContent = ({
  buttonLink,
  buttonText,
  description,
  image,
  includeButton = false,
  includeSecondaryButton = false,
  isLeft = false,
  isWide = false,
  padding,
  secondaryButtonLink,
  secondaryButtonText,
  title,
  quote,
}: Props) => {
  return (
    <Section padding={padding}>
      <motion.div initial="hidden" animate="visible" variants={variants}>
        <MxSectionContainer tw="text-gray-600 font-light">
          <MxSectionFirstContainer
            tw="h-full md:col-start-1 col-end-7"
            css={[isLeft && tw`md:(!col-start-9 !col-end-16)`]}
          >
            <MxSectionTextContainer
              tw="mb-8 px-4 md:(mb-8 pr-4 h-full)"
              css={[isLeft && tw`!px-4 !mb-8 md:(!gap-y-6 !pr-4) lg:!pr-10`]}
            >
              <MxSectionTitle
                tw="max-w-lg md:max-w-md"
                css={[
                  isWide &&
                    tw`!max-w-lg !text-3xl md:(!text-4xl !max-w-md !relative)`,
                ]}
              >
                {title}
              </MxSectionTitle>
              <div
                css={[!quote && !includeButton && tw`lg:(absolute bottom-0)`]}
              >
                <MxSectionDescription tw="max-w-md">
                  {description}
                </MxSectionDescription>
                {quote && (
                  <p
                    tw="text-gray-700 text-base max-w-md pt-6 leading-7 font-medium"
                    dangerouslySetInnerHTML={{
                      __html: quote,
                    }}
                  />
                )}
                {includeButton && (
                  <Button
                    tw="my-4 mx-1 lg:(mb-0 mt-8 mx-0)"
                    color="default"
                    onClick={() => {
                      navigate(buttonLink ?? '')
                    }}
                  >
                    {buttonText}
                  </Button>
                )}
                {includeSecondaryButton && (
                  <Button
                    tw="m-0 mx-1 relative lg:(mt-8 mb-0 mx-6)"
                    color="olive"
                    onClick={() => {
                      navigate(secondaryButtonLink ?? '')
                    }}
                  >
                    {secondaryButtonText}
                  </Button>
                )}
              </div>
            </MxSectionTextContainer>
          </MxSectionFirstContainer>
          <MxSectionSecondContainer
            tw="mb-auto mt-3 row-start-auto col-span-full grid gap-y-3 gap-x-3 md:(gap-y-6 col-start-9 col-end-16)"
            css={[isLeft && tw`md:(!col-start-1 !col-end-8 !row-start-1)`]}
          >
            <div
              tw="col-start-1 col-end-16 pl-0 relative md:(mt-0 pl-4)"
              css={[isLeft && tw`md:(!pl-6)`]}
            >
              <GatsbyImage
                alt="kitchen design"
                tw="md:(h-full w-full)"
                image={image as IGatsbyImageData}
              />
            </div>
          </MxSectionSecondContainer>
        </MxSectionContainer>
      </motion.div>
    </Section>
  )
}
