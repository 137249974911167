import React from 'react'

import { motion } from 'framer-motion'

import 'twin.macro'
import { Section } from '../section'

const variants = {
  visible: {
    opacity: 1,
    y: 0,
    transition: { ease: 'easeOut', delay: 0.25, duration: 0.75 },
  },
  hidden: { opacity: 0 },
}

interface Props {
  formLogo?: string
  partnerLogo?: string
}
export const BrandsSection = ({ formLogo, partnerLogo }: Props) => {
  return (
    <Section padding={'top'}>
      <motion.div
        initial="hidden"
        animate="visible"
        variants={variants}
        tw="md:(px-8) lg:(px-20)"
      >
        <div tw="flex flex-row justify-around mx-auto w-full md:w-4/6">
          <img tw="h-4 xxs:h-6 md:h-auto" src={partnerLogo} />
          <img tw="h-4 xxs:h-6 md:h-auto" src={formLogo} />
        </div>
      </motion.div>
    </Section>
  )
}

export default BrandsSection
