import React from 'react'

import { navigate } from 'gatsby'
import tw from 'twin.macro'

import fireEvent from 'context/tracking/events'
import { FormContactSource } from 'types/crm'

import { CaseStudiesPopup } from './case-studies-popup'
import {
  MxButton as Button,
  MxSectionDescription,
  MxSectionTitle,
} from './components/section-components'
import { Section } from './section'
import { resourcesUrls } from '../views/utils'

const CustomBottomCTA = ({ content = {} }: { content?: any }) => {
  const [openPopup, setOpenPopup] = React.useState(false)
  const { moduleMisc, moduleTitle, contentSlots } = content

  if (!Object.keys(content).length) return null

  return (
    <>
      <Section css={[tw`pt-0 pb-16 md:py-16`]}>
        <div tw="grid grid-cols-16 grid-rows-1">
          <div tw="row-start-1 row-end-1 col-start-1 pl-4 col-end-16 space-y-8 mb-16 xs:(mb-12) md:(py-28 row-start-1 row-end-3 col-start-1 self-center col-end-8) lg:(mb-0 pl-10 space-y-8 row-end-1 col-end-9)">
            <MxSectionTitle>{moduleTitle}</MxSectionTitle>
            <MxSectionDescription>
              {moduleMisc.description}
            </MxSectionDescription>
            <Button
              onClick={() => {
                fireEvent({
                  type: 'cta_clicked',
                  ctaStr: 'Try Form For Free // Bottom CTA',
                })
                navigate(moduleMisc.action.url)
              }}
              color="default"
            >
              {moduleMisc.action.text}
            </Button>
          </div>
          <div tw="pl-4 mb-8 col-start-1 col-end-15 self-end flex items-start xs:(col-start-1 col-end-9 mb-0) md:(w-auto mb-12 self-center row-start-1 row-end-1 col-start-9 col-end-15 items-stretch pl-0 flex-col) lg:(self-end col-start-9 col-end-13 pb-0)">
            <div tw="lg:(grid items-center grid-cols-1 grid-rows-1 mb-16)">
              <div tw="z-10 self-center flex justify-center col-start-1 col-end-1 row-start-1 row-end-1">
                <img
                  tw="w-28 h-28 lg:(h-40 w-40)"
                  src={contentSlots[0]?.photos[0]?.file.url}
                />
              </div>
            </div>
            <div tw="pl-4 space-y-2 md:(space-y-4 pr-12)">
              <p tw="text-2xl leading-tight font-thin text-black max-w-xs md:(text-3xl pr-10)">
                {moduleMisc.action.secondaryTitle}
              </p>
              <Button
                kind="link"
                onClick={() => {
                  setOpenPopup(true)
                }}
              >
                {moduleMisc.action.secondaryText}
              </Button>
            </div>
          </div>
          <div tw="ml-4 col-start-1 col-end-15 self-end flex items-start relative xs:(col-start-9 col-end-16) sm:(ml-0) md:(w-auto items-stretch flex-col row-start-2 row-end-2 col-start-9 col-end-15) lg:(row-start-1 row-end-1 self-start col-start-13 col-end-17 pb-0)">
            <div tw="lg:(grid items-center grid-cols-1 grid-rows-1 mb-16)">
              <div tw="z-10 self-center flex justify-center col-start-1 col-end-1 row-start-1 row-end-1">
                <img
                  tw="w-28 h-28 lg:(h-40 w-40)"
                  src={contentSlots[0]?.photos[1]?.file.url}
                />
              </div>
            </div>
            <div tw="pl-4 space-y-2 md:(space-y-4 pr-12)">
              <p tw="text-2xl leading-tight font-thin text-black max-w-xs md:(text-3xl pr-10)">
                {moduleMisc.action.tertiaryTitle}
              </p>
              <Button
                kind="link"
                onClick={() => {
                  navigate(moduleMisc.action.tertiaryUrl)
                }}
              >
                {moduleMisc.action.tertiaryText}
              </Button>
            </div>
          </div>
        </div>
      </Section>
      <CaseStudiesPopup
        contactSource={FormContactSource.CATALOG}
        onCloseModal={() => {
          setOpenPopup(false)
        }}
        onSubmit={() => {
          setOpenPopup(false)
          window.open(resourcesUrls.caseStudies)
        }}
        openModal={openPopup}
      />
    </>
  )
}

export { CustomBottomCTA }
