import { graphql, useStaticQuery } from 'gatsby'

export const useBuildZoomItems = () => {
  const { content } = useStaticQuery(graphql`
    query {
      content: allContentfulMxContainer(
        filter: { contentful_id: { eq: "6lEP2mVCjhuteaiLAxRmXX" } }
      ) {
        data: edges {
          node {
            description
            actions {
              text
              url
            }
            title
            image {
              localFile {
                childImageSharp {
                  gatsbyImageData(layout: CONSTRAINED, width: 1200)
                }
              }
            }
            sectionModules {
              ... on ContentfulMxModules {
                contentSlots {
                  slotTitle
                  description
                  photos {
                    file {
                      url
                    }
                  }
                }
                moduleTitle
                moduleMisc {
                  action {
                    secondaryText
                    secondaryTitle
                    secondaryUrl
                    tertiaryText
                    tertiaryTitle
                    tertiaryUrl
                    text
                    url
                  }
                  buttonText
                  description
                  guideName
                  guideSource
                  isLeft
                  popupDescription
                  popupTitle
                  quote
                }
                images {
                  title
                  description
                  file {
                    url
                  }
                  localFile {
                    childImageSharp {
                      gatsbyImageData(layout: CONSTRAINED, width: 1200)
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `)
  return content
}
